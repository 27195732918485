







import Vue from 'vue'
export default class NoRights extends Vue {}
